.overlay {
    position: absolute;
    top: 0;
	bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.8);
	transition: opacity 200ms;
    display: none;
    opacity: 0;
    z-index: 1000; 
    .cancel {
        position: absolute;
        width: 100%;
        height: 100%;
        cursor: default;
    }
    &:target {
    display: block;
    opacity: 1;
    }
}

.popup {
	margin: 45% auto auto auto;
	background: #fff;
	border: 1px solid #666;
	width: auto;
	box-shadow: 0 0 50px rgba(0,0,0,0.5);
    position: relative;
    img {
        width: 100%;
        max-width: 1050px;
    }
    @media screen and (min-width: $iPad) {
        margin:25% auto auto auto;
    }
    @media screen and (min-width: 1100px) {
        margin:auto auto auto auto;
        height: 100%;
        img {
            position: absolute;
            max-height: 85%;
            width: 85%;
            
            top: 50%;
            left: 50%;

            transform: translate(-50%, -50%);
        }
    }
}
.close {
    position: absolute;
    
    top: 4%;
    right: 3%;
    opacity: 0.8;
    transition: all 200ms;
    font-size: 50px;
    font-weight: bold;
    background: radial-gradient(#20a2ca, $EliteBlue);
    padding: 14px 20px;
    border: .5px solid grey;
    border-radius: 10px 10px 10px 10px;
    box-shadow: -1px 3px 8px 1px rgba(65, 65, 65, .8);
    text-decoration: none;
    color: black;
    &:hover {
      opacity: 1;
    }
    @media screen and (min-width: $iPad) {
        top: 3%;
        right: 6%;
        font-size: 70px;
    }
    @media screen and (min-width: $iPadPro) {
        right: 6%;
        font-size: 100px;
        padding: 14px 30px;
    }
    @media screen and (min-width: 1100px) {
        right: 5%;
        top: 4.5%;
    }
}

.modal-button {
    position: absolute;
    background-color: rgba(0,0,0,0.5);
    color: $EliteBlue;
    font-size: 30px;
    padding: 20px 30px;
    border: 3px solid darkgray;
    @media screen and (min-width) {

    }
}
.next {
    bottom: 5%;
    right: 5%;
    @media screen and (min-width: $iPad) {
        bottom: 60px;
        right: 18%;
    }
    @media screen and (min-width: $iPadPro) {
        bottom: 110px;
        right: 15%;
        padding: 30px 40px;
    }
    @media screen and (min-width: 1100px) {
        border: 6px solid $EliteBlue;
        color: white;
        bottom: 45%;
        right: 5%;
    }
}
.previous {
    bottom: 5%;
    left: 5%;
    @media screen and (min-width: $iPad) {
        bottom: 60px;
        left: 18%;
    }
    @media screen and (min-width: $iPadPro) {
        bottom: 110px;
        left: 15%;
        padding: 30px 40px;
    }
    @media screen and (min-width: 1100px) {
        border: 6px solid $EliteBlue;
        color: white;
        bottom: 45%;
        left: 5%;
    }
}