@charset "UTF-8";
/* ------------------------------------------------ COLOR SCHEME ------------------------------------------------------------- */
.Topshadow {
  box-shadow: 0px -0.5px 4px rgba(103, 103, 104, 0.5); }

* {
  box-sizing: border-box;
  font-family: 'Lato', sans-serif; }

a:hover {
  cursor: pointer; }

h1, h2, h3, h4 {
  text-align: center;
  margin: 0px;
  font-size: 1em; }

h1 {
  font-size: 1.20em;
  margin-bottom: 10px; }
  @media screen and (min-width: 768px) {
    h1 {
      font-size: 1.8em;
      margin-bottom: 25px; } }
  @media screen and (min-width: 1024px) {
    h1 {
      margin-bottom: 30px; } }
  @media screen and (max-width: 320px) {
    h1 {
      font-size: 1.0em; } }

h2 {
  font-size: 1.10em;
  margin: 10px 0 20px 0;
  font-family: Roboto; }
  @media screen and (min-width: 768px) {
    h2 {
      font-size: 1.6em; } }
  @media screen and (min-width: 1024px) {
    h2 {
      margin-bottom: 35px; } }
  @media screen and (max-width: 320px) {
    h2 {
      font-size: 0.9em;
      margin: 10px 0px; } }

h3 {
  font-size: 1.0em;
  margin-bottom: 8px; }
  @media screen and (min-width: 768px) {
    h3 {
      font-size: 1.3em; } }
  @media screen and (min-width: 1024px) {
    h3 {
      margin-bottom: 30px; } }
  @media screen and (max-width: 320px) {
    h3 {
      font-size: 0.8em; } }

h4 {
  font-size: 0.9em;
  margin-bottom: 8px; }
  @media screen and (min-width: 768px) {
    h4 {
      font-size: 1.2em; } }
  @media screen and (max-width: 320px) {
    h4 {
      font-size: 0.75em;
      margin-bottom: 5px; } }

p {
  color: #fffbf7;
  font-size: .9em;
  text-align: center; }
  @media screen and (max-width: 320px) {
    p {
      font-size: 0.7em; } }
  @media screen and (min-width: 768px) {
    p {
      font-size: 1.2em; } }
  @media screen and (min-width: 1024px) {
    p {
      font-size: 1.4em; } }

html, body {
  background: linear-gradient(to bottom, #a5dbeb, #b8b9bb);
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100vw;
  height: auto;
  color: #fffbf7;
  padding: 0;
  margin: 0; }

#body-content {
  padding-top: 157.5px;
  height: auto;
  min-height: calc(100vh - 120px); }
  @media screen and (min-width: 1024px) {
    #body-content {
      padding-top: 245px; } }

a:link {
  text-decoration: none; }

a:hover {
  background: #fbf8c2;
  border-color: #129fca;
  color: #0d6f8d; }

a.contact-button {
  display: inline-block;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  text-transform: uppercase;
  font-family: 'Roboto',sans-serif;
  font-weight: 400;
  text-align: center;
  transition: all 0.15s; }

a.contact-button:hover {
  color: #fffbf7;
  border-color: #129fca;
  background-color: #fbf8c2; }

a.contact-button:active {
  color: #BBBBBB;
  border-color: #BBBBBB; }

@media all and (max-width: 30em) {
   a.contact-button {
    display: block;
    margin: 0.4em auto; } }

.large-click {
  padding: 2em 6em;
  border: 0.5em solid #fbf8c2;
  border-radius: 0.50em;
  color: black; }
  @media screen and (max-width: 320px) {
    .large-click {
      padding: 2em 4em; } }
  @media screen and (min-width: 768px) {
    .large-click {
      padding: 3em 9em; } }
  @media screen and (min-width: 1024px) {
    .large-click {
      border: 1em solid #fbf8c2; } }
  @media screen and (min-width: 1100px) {
    .large-click {
      border: 0.75em solid #fbf8c2;
      padding: 3.0em 11.00em;
      font-size: 1.0em;
      width: 100%; } }

a.contact-button.large-click:hover {
  color: #0d6f8d; }

.small-click {
  padding: .5em 1.5em;
  border: 0.2em solid #129fca;
  color: #129fca;
  border-radius: 0.40em;
  width: 100%;
  font-size: 25px; }
  @media screen and (min-width: 768px) {
    .small-click {
      padding: 0.30em .30em;
      border: 0.16em solid #129fca; } }
  @media screen and (min-width: 1100px) {
    .small-click {
      padding: 1.0em 3.00em;
      border: 0.2em solid #129fca; } }

a.contact-button.small-click:hover {
  color: #0d6f8d; }

.footer-click {
  padding: .25em 0.80em;
  border: 0.05em solid #fffbf7;
  color: #fffbf7;
  font-size: .8em; }
  @media screen and (min-width: 768px) {
    .footer-click {
      padding: 0.30em .50em;
      border: 0.16em solid #fffbf7; } }
  @media screen and (min-width: 1100px) {
    .footer-click {
      padding: 1.0em 3.00em;
      border: 0.2em solid #fffbf7; } }

.phone-icon {
  font-size: 0.90em;
  color: black;
  text-decoration: none;
  padding-top: 20px;
  text-shadow: 2px 2px 5px #cbccce; }
  @media screen and (max-width: 320px) {
    .phone-icon {
      font-size: .70em; } }
  @media screen and (min-width: 768px) {
    .phone-icon {
      font-size: 2em;
      padding-top: 10px; } }

.row {
  width: 100%;
  height: 15vh;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative; }
  @media screen and (max-width: 1024px) {
    .row {
      height: 5vh; } }

.svg-separator {
  display: block;
  background: 0 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  -webkit-transform: translateY(-100%) translateY(2px);
  transform: translateY(-100%) translateY(2px);
  width: 100%; }

.sep3 {
  transform: translateY(-100%) translateY(2px) scale(1, 1);
  transform-origin: top; }

#logo-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  z-index: 110;
  padding-left: 5%; }
  #logo-wrapper #logo {
    position: absolute;
    cursor: pointer;
    overflow: hidden; }
    #logo-wrapper #logo img {
      display: block;
      height: 100%;
      width: 100%; }
  #logo-wrapper .large {
    background-color: white;
    width: 125px;
    height: 125px;
    border: 4px solid #dcddde;
    border-radius: 30%;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2); }
  #logo-wrapper .small {
    width: 70px;
    height: 70px;
    border: 4px solid #dcddde;
    border-radius: 30%;
    box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (max-width: 768px), screen and (max-height: 480px) {
    #logo-wrapper {
      justify-content: flex-start;
      width: 30%; }
      #logo-wrapper .large {
        width: 70px;
        height: 70px; } }
  @media screen and (min-width: 1024px) {
    #logo-wrapper {
      justify-content: flex-start;
      width: 30%; } }
  @media screen and (max-width: 320px) {
    #logo-wrapper {
      width: 75px; } }

#nav-menu {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%; }
  #nav-menu #menu-icon {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px; }
    #nav-menu #menu-icon #bar1,
    #nav-menu #menu-icon #bar2,
    #nav-menu #menu-icon #bar3 {
      width: 32px;
      height: 5px;
      background-color: #80d3d9;
      transition-duration: .3s;
      transition-timing-function: ease-out; }
    #nav-menu #menu-icon #bar1 {
      margin: 5px 0px 0px; }
    #nav-menu #menu-icon #bar2 {
      margin: 5px 0px; }
    #nav-menu #menu-icon #bar3 {
      margin: 0px 0px 5px; }
    #nav-menu #menu-icon.open #bar1,
    #nav-menu #menu-icon.open #bar3 {
      margin: 0px;
      padding: 0px;
      height: 0px;
      opacity: 0; }
    #nav-menu #menu-icon.open #bar2 {
      box-sizing: border-box;
      border-top: 5px solid #80d3d9;
      border-left: 5px solid #0b617b;
      width: 24px;
      height: 24px;
      background-color: transparent;
      transform: rotate(45deg) translate(3px, 3px); }
  #nav-menu #menu-icon {
    display: none; }
  #nav-menu #site-links {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 130;
    width: 100%; }
    #nav-menu #site-links .siteLink {
      color: black;
      padding: .5em 1.5em;
      text-align: center;
      font-weight: bold;
      text-decoration: none;
      white-space: nowrap;
      border-radius: 20px;
      cursor: pointer; }
      @media screen and (min-width: 1200px) {
        #nav-menu #site-links .siteLink {
          padding: .5em 3em; } }
      #nav-menu #site-links .siteLink:hover {
        color: #0d6f8d;
        background: #fbf8c2;
        border: 5px solid #129fca; }
    #nav-menu #site-links .big-menu-btn {
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      font-size: 20px;
      background: linear-gradient(to bottom, #64dbe6, #129fca, Black);
      color: black;
      padding: .75em 3em;
      border-radius: 10px; }
      @media screen and (min-width: 768px) {
        #nav-menu #site-links .big-menu-btn {
          color: white;
          padding: .8em 2.25em; } }
      @media screen and (min-width: 1024px) {
        #nav-menu #site-links .big-menu-btn {
          border-radius: 40px;
          color: white;
          padding: 1em 1.75em; } }
      @media screen and (min-width: 1200px) {
        #nav-menu #site-links .big-menu-btn {
          font-size: 40px;
          color: white;
          padding: 1.25em 2.75em; } }
  @media screen and (max-width: 767px), screen and (max-height: 480px) {
    #nav-menu {
      width: 50%; }
      #nav-menu #site-links .siteLink {
        font-size: 14px;
        margin: 10px; } }
  @media screen and (min-width: 768px) {
    #nav-menu {
      width: 70%; }
      #nav-menu #site-links .siteLink {
        font-size: 22px;
        margin: 10px; } }
  @media screen and (min-width: 1024px) {
    #nav-menu {
      width: 70%; }
      #nav-menu #site-links .siteLink {
        margin: 10px;
        font-size: 30px; } }
  @media screen and (max-width: 570px) {
    #nav-menu {
      position: relative;
      flex-flow: column nowrap; }
      #nav-menu #menu-icon {
        display: flex; }
      #nav-menu #site-links {
        position: absolute;
        top: 87.5px;
        flex-flow: column nowrap;
        height: 0px;
        overflow: hidden;
        background: rgba(5, 42, 54, 0.7);
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px; }
        #nav-menu #site-links .siteLink {
          margin: 16px 10px;
          padding: 15px;
          width: 85%;
          border-radius: 30px;
          background: linear-gradient(to bottom, #fbfbf8, rgba(206, 206, 206, 0.8), #fbfbf8);
          box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
          #nav-menu #site-links .siteLink.active {
            background: linear-gradient(to top, #fffbf7, #D0D0D0); } }
  @media screen and (max-width: 320px) {
    #nav-menu {
      width: calc(100% - 75px); }
      #nav-menu #menu-icon {
        transform: translateX(calc((100vw - 120px - 20px) / 2 - 60px)); }
      #nav-menu #site-links {
        top: 70px;
        transform: translateX(calc((100vw - 140px - 20px) / 2 - 50px)); } }

#menu-icon {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px; }
  #menu-icon #bar1,
  #menu-icon #bar2,
  #menu-icon #bar3 {
    width: 32px;
    height: 5px;
    background-color: #80d3d9;
    transition-duration: .3s;
    transition-timing-function: ease-out; }
  #menu-icon #bar1 {
    margin: 5px 0px 0px; }
  #menu-icon #bar2 {
    margin: 5px 0px; }
  #menu-icon #bar3 {
    margin: 0px 0px 5px; }
  #menu-icon.open #bar1,
  #menu-icon.open #bar3 {
    margin: 0px;
    padding: 0px;
    height: 0px;
    opacity: 0; }
  #menu-icon.open #bar2 {
    box-sizing: border-box;
    border-top: 5px solid #80d3d9;
    border-left: 5px solid #0b617b;
    width: 24px;
    height: 24px;
    background-color: transparent;
    transform: rotate(45deg) translate(3px, 3px); }

#top-overlay {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 87.5px;
  z-index: 10;
  background: #fbfbf8;
  background-image: linear-gradient(to bottom, #f8fbfb, #eef0f9);
  box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  @media screen and (min-width: 768px) {
    #top-overlay {
      height: 87.5px; } }
  @media screen and (min-width: 1024px) {
    #top-overlay {
      height: 175px; } }
  #top-overlay #overlay-container {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    height: 100%;
    padding: 0px 10px; }
    #top-overlay #overlay-container #logo-wrapper {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      z-index: 110;
      padding-left: 5%; }
      #top-overlay #overlay-container #logo-wrapper #logo {
        position: absolute;
        cursor: pointer;
        overflow: hidden; }
        #top-overlay #overlay-container #logo-wrapper #logo img {
          display: block;
          height: 100%;
          width: 100%; }
      #top-overlay #overlay-container #logo-wrapper .large {
        background-color: white;
        width: 125px;
        height: 125px;
        border: 4px solid #dcddde;
        border-radius: 30%;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2); }
      #top-overlay #overlay-container #logo-wrapper .small {
        width: 70px;
        height: 70px;
        border: 4px solid #dcddde;
        border-radius: 30%;
        box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.2), 1px 0px 4px 0px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.2), -1px 0px 4px 0px rgba(0, 0, 0, 0.2); }
      @media screen and (max-width: 768px), screen and (max-height: 480px) {
        #top-overlay #overlay-container #logo-wrapper {
          justify-content: flex-start;
          width: 30%; }
          #top-overlay #overlay-container #logo-wrapper .large {
            width: 70px;
            height: 70px; } }
      @media screen and (min-width: 1024px) {
        #top-overlay #overlay-container #logo-wrapper {
          justify-content: flex-start;
          width: 30%; } }
      @media screen and (max-width: 320px) {
        #top-overlay #overlay-container #logo-wrapper {
          width: 75px; } }
    #top-overlay #overlay-container #nav-menu {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%; }
      #top-overlay #overlay-container #nav-menu #menu-icon {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar1,
        #top-overlay #overlay-container #nav-menu #menu-icon #bar2,
        #top-overlay #overlay-container #nav-menu #menu-icon #bar3 {
          width: 32px;
          height: 5px;
          background-color: #80d3d9;
          transition-duration: .3s;
          transition-timing-function: ease-out; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar1 {
          margin: 5px 0px 0px; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar2 {
          margin: 5px 0px; }
        #top-overlay #overlay-container #nav-menu #menu-icon #bar3 {
          margin: 0px 0px 5px; }
        #top-overlay #overlay-container #nav-menu #menu-icon.open #bar1,
        #top-overlay #overlay-container #nav-menu #menu-icon.open #bar3 {
          margin: 0px;
          padding: 0px;
          height: 0px;
          opacity: 0; }
        #top-overlay #overlay-container #nav-menu #menu-icon.open #bar2 {
          box-sizing: border-box;
          border-top: 5px solid #80d3d9;
          border-left: 5px solid #0b617b;
          width: 24px;
          height: 24px;
          background-color: transparent;
          transform: rotate(45deg) translate(3px, 3px); }
      #top-overlay #overlay-container #nav-menu #menu-icon {
        display: none; }
      #top-overlay #overlay-container #nav-menu #site-links {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 130;
        width: 100%; }
        #top-overlay #overlay-container #nav-menu #site-links .siteLink {
          color: black;
          padding: .5em 1.5em;
          text-align: center;
          font-weight: bold;
          text-decoration: none;
          white-space: nowrap;
          border-radius: 20px;
          cursor: pointer; }
          @media screen and (min-width: 1200px) {
            #top-overlay #overlay-container #nav-menu #site-links .siteLink {
              padding: .5em 3em; } }
          #top-overlay #overlay-container #nav-menu #site-links .siteLink:hover {
            color: #0d6f8d;
            background: #fbf8c2;
            border: 5px solid #129fca; }
        #top-overlay #overlay-container #nav-menu #site-links .big-menu-btn {
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
          font-size: 20px;
          background: linear-gradient(to bottom, #64dbe6, #129fca, Black);
          color: black;
          padding: .75em 3em;
          border-radius: 10px; }
          @media screen and (min-width: 768px) {
            #top-overlay #overlay-container #nav-menu #site-links .big-menu-btn {
              color: white;
              padding: .8em 2.25em; } }
          @media screen and (min-width: 1024px) {
            #top-overlay #overlay-container #nav-menu #site-links .big-menu-btn {
              border-radius: 40px;
              color: white;
              padding: 1em 1.75em; } }
          @media screen and (min-width: 1200px) {
            #top-overlay #overlay-container #nav-menu #site-links .big-menu-btn {
              font-size: 40px;
              color: white;
              padding: 1.25em 2.75em; } }
      @media screen and (max-width: 767px), screen and (max-height: 480px) {
        #top-overlay #overlay-container #nav-menu {
          width: 50%; }
          #top-overlay #overlay-container #nav-menu #site-links .siteLink {
            font-size: 14px;
            margin: 10px; } }
      @media screen and (min-width: 768px) {
        #top-overlay #overlay-container #nav-menu {
          width: 70%; }
          #top-overlay #overlay-container #nav-menu #site-links .siteLink {
            font-size: 22px;
            margin: 10px; } }
      @media screen and (min-width: 1024px) {
        #top-overlay #overlay-container #nav-menu {
          width: 70%; }
          #top-overlay #overlay-container #nav-menu #site-links .siteLink {
            margin: 10px;
            font-size: 30px; } }
      @media screen and (max-width: 570px) {
        #top-overlay #overlay-container #nav-menu {
          position: relative;
          flex-flow: column nowrap; }
          #top-overlay #overlay-container #nav-menu #menu-icon {
            display: flex; }
          #top-overlay #overlay-container #nav-menu #site-links {
            position: absolute;
            top: 87.5px;
            flex-flow: column nowrap;
            height: 0px;
            overflow: hidden;
            background: rgba(5, 42, 54, 0.7);
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px; }
            #top-overlay #overlay-container #nav-menu #site-links .siteLink {
              margin: 16px 10px;
              padding: 15px;
              width: 85%;
              border-radius: 30px;
              background: linear-gradient(to bottom, #fbfbf8, rgba(206, 206, 206, 0.8), #fbfbf8);
              box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
              #top-overlay #overlay-container #nav-menu #site-links .siteLink.active {
                background: linear-gradient(to top, #fffbf7, #D0D0D0); } }
      @media screen and (max-width: 320px) {
        #top-overlay #overlay-container #nav-menu {
          width: calc(100% - 75px); }
          #top-overlay #overlay-container #nav-menu #menu-icon {
            transform: translateX(calc((100vw - 120px - 20px) / 2 - 60px)); }
          #top-overlay #overlay-container #nav-menu #site-links {
            top: 70px;
            transform: translateX(calc((100vw - 140px - 20px) / 2 - 50px)); } }

#footer-wrapper {
  width: 100vw;
  height: 120px;
  background: linear-gradient(to top, black, #1F1F1F);
  z-index: 2; }

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  margin: 0px auto;
  max-width: 960px;
  height: 100%; }
  .footer small {
    color: white;
    text-align: center; }
    .footer small a {
      color: #001fff; }
    .footer small img {
      margin: 0px 10px; }

#bottom-overlay {
  display: none;
  flex-flow: row nowrap;
  justify-content: center;
  position: fixed;
  bottom: 0px;
  right: 0px;
  width: 100%;
  padding: 5px 0px;
  background: linear-gradient(to top, black, #1F1F1F);
  z-index: 10; }
  #bottom-overlay button {
    margin: 0px 8px;
    padding: 5px 0px;
    width: 110px;
    height: auto;
    background: linear-gradient(to top, #001fff, #5c70ff);
    color: white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 1.0em;
    text-decoration: none;
    white-space: nowrap;
    overflow: hidden; }
    #bottom-overlay button:active {
      background: linear-gradient(to bottom, #001fff, #0019cc); }
  @media screen and (max-width: 570px), screen and (max-height: 498px) {
    #bottom-overlay button {
      margin: 0px 4px;
      font-size: 18px; } }

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  transition: opacity 200ms;
  display: none;
  opacity: 0;
  z-index: 1000; }
  .overlay .cancel {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: default; }
  .overlay:target {
    display: block;
    opacity: 1; }

.popup {
  margin: 45% auto auto auto;
  background: #fff;
  border: 1px solid #666;
  width: auto;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative; }
  .popup img {
    width: 100%;
    max-width: 1050px; }
  @media screen and (min-width: 768px) {
    .popup {
      margin: 25% auto auto auto; } }
  @media screen and (min-width: 1100px) {
    .popup {
      margin: auto auto auto auto;
      height: 100%; }
      .popup img {
        position: absolute;
        max-height: 85%;
        width: 85%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); } }

.close {
  position: absolute;
  top: 4%;
  right: 3%;
  opacity: 0.8;
  transition: all 200ms;
  font-size: 50px;
  font-weight: bold;
  background: radial-gradient(#20a2ca, #129fca);
  padding: 14px 20px;
  border: .5px solid grey;
  border-radius: 10px 10px 10px 10px;
  box-shadow: -1px 3px 8px 1px rgba(65, 65, 65, 0.8);
  text-decoration: none;
  color: black; }
  .close:hover {
    opacity: 1; }
  @media screen and (min-width: 768px) {
    .close {
      top: 3%;
      right: 6%;
      font-size: 70px; } }
  @media screen and (min-width: 1024px) {
    .close {
      right: 6%;
      font-size: 100px;
      padding: 14px 30px; } }
  @media screen and (min-width: 1100px) {
    .close {
      right: 5%;
      top: 4.5%; } }

.modal-button {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #129fca;
  font-size: 30px;
  padding: 20px 30px;
  border: 3px solid darkgray; }

.next {
  bottom: 5%;
  right: 5%; }
  @media screen and (min-width: 768px) {
    .next {
      bottom: 60px;
      right: 18%; } }
  @media screen and (min-width: 1024px) {
    .next {
      bottom: 110px;
      right: 15%;
      padding: 30px 40px; } }
  @media screen and (min-width: 1100px) {
    .next {
      border: 6px solid #129fca;
      color: white;
      bottom: 45%;
      right: 5%; } }

.previous {
  bottom: 5%;
  left: 5%; }
  @media screen and (min-width: 768px) {
    .previous {
      bottom: 60px;
      left: 18%; } }
  @media screen and (min-width: 1024px) {
    .previous {
      bottom: 110px;
      left: 15%;
      padding: 30px 40px; } }
  @media screen and (min-width: 1100px) {
    .previous {
      border: 6px solid #129fca;
      color: white;
      bottom: 45%;
      left: 5%; } }

/* ------------------------------------------------ FULL SCREEN HEIGHT CONTAINERS -------------------------------------------------- */
.flex-100 {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100vw;
  height: calc(100vh - 140px); }
  @media screen and (min-width: 768px) {
    .flex-100 {
      height: calc(100vh - 157.5px); } }
  @media screen and (min-width: 1024px) {
    .flex-100 {
      height: calc(100vh - 245px); } }

.full-view-height-sub-menu-large {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: 100vw;
  min-height: calc(100vh - 140px); }
  @media screen and (min-width: 768px) {
    .full-view-height-sub-menu-large img {
      max-height: 417px;
      max-width: 417px; } }
  @media screen and (min-width: 1024px) {
    .full-view-height-sub-menu-large {
      height: calc(100vh - 245px); } }

.full-view-height-sub-menu-small {
  position: relative;
  width: 100vw;
  height: calc(100vh - 157.5px); }
  @media screen and (min-width: 1024px) {
    .full-view-height-sub-menu-small {
      height: calc(100vh - 245px); } }

.three-fourths-vh {
  position: relative;
  width: 100vw;
  height: 75vh; }
  @media screen and (min-width: 768px) {
    .three-fourths-vh {
      height: calc(75vh - 520px); } }
  @media screen and (min-width: 1024px) {
    .three-fourths-vh {
      height: calc(75vh - 670px); } }
  @media screen and (min-width: 1100px) {
    .three-fourths-vh {
      height: calc(75vh - 100px); } }

.three-fourths-vh-sub-menu {
  width: 100%;
  min-height: 75%; }
  @media screen and (min-width: 768px) {
    .three-fourths-vh-sub-menu {
      min-height: calc(75vh - 100px); } }
  @media screen and (min-width: 1100px) {
    .three-fourths-vh-sub-menu {
      height: 75%; } }

/* ------------------------------------------------- 2/3 SCREEN HEIGHT CONTAINERS ---------------------------------------------- */
.two-thirds-vh-sub-menu {
  width: 100vw;
  height: calc(66.66vh - 105px); }
  @media screen and (min-width: 1024px) {
    .two-thirds-vh-sub-menu {
      height: calc(66.66vh - 157.5px); } }

.two-thirds-height-content-container {
  height: 66%;
  width: 100%; }

.display-flex {
  display: flex; }

/* -------------------------------------------------- HALF HEIGHT CONTAINERS ------------------------------------------- */
.half-view-height-sub-menu-container {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100vw;
  height: 75vh; }
  @media screen and (min-width: 768px) {
    .half-view-height-sub-menu-container {
      height: 50vh; } }

.half-height-content-container {
  position: relative;
  width: 100%;
  height: 50%; }

.half-height-flex-container {
  display: flex;
  min-height: 50%;
  width: 100%; }

.absolute-half-bottom {
  position: absolute;
  top: 50%; }

.absolute-half-top {
  position: absolute;
  top: 0%; }

/* ------------------------------------------------- 1/3 SCREEN HEIGHT CONTAINERS ---------------------------------------------- */
.flex-33 {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  width: 100vw;
  min-height: calc(33.33vh - 35px); }

.third-vh-sub-menu {
  width: 100vw;
  min-height: 33%; }

.third-height-content-container {
  position: relative;
  min-height: 33.33%;
  width: 100%; }

/* ------------------------------------------------- HALF SCREEN WIDTH CONTAINERS ---------------------------------------------- */
.mobile-full-desktop-half {
  position: static;
  width: 100%;
  height: 50%;
  display: inline-block; }
  @media screen and (min-width: 768px) {
    .mobile-full-desktop-half {
      width: 50%;
      height: 100%; } }

.half-width-relative-container {
  position: relative;
  height: 100%;
  width: 50%;
  display: inline-block; }

.half-width-container {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 50%; }

.flex-order-1 {
  order: 1; }

.flex-order-2 {
  order: 2; }

.flex-order-3 {
  order: 3; }

/* --------------------------------------------- GRID CONTAINERS --------------------------------------*/
.grid-container {
  display: grid;
  position: relative;
  background-color: #fbf8c2;
  padding: .6em;
  justify-content: center; }

.auto-c-3 {
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr)); }
  @media screen and (min-width: 768px) {
    .auto-c-3 {
      grid-template-columns: repeat(3, 1fr); } }

.grid-item {
  background-color: #fbf8c2;
  font-size: 20px;
  text-align: center;
  overflow: hidden; }
  @media screen and (min-width: 768px) {
    .grid-item {
      background-color: white;
      text-align: center; } }
  @media screen and (min-width: 1024px) {
    .grid-item {
      font-size: 30px; } }

.grid-item img {
  align-self: center; }

/* ----------------------------------------------- IMAGE POSITIONING CONTAINERS ----------------------------------------- */
.image-box-shift-right-quarter {
  width: 100%;
  height: 100%;
  padding-left: 50%; }

.image-box-shift-right-half {
  position: relative;
  width: auto;
  height: 100%;
  max-height: 100%;
  padding-left: 50%;
  overflow: hidden; }
  .image-box-shift-right-half img {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50%; }

.image-box-quarter-height-container {
  background-image: url("./images/content/lug-nut-impact-driver.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  height: 100%;
  width: 100%; }
  @media screen and (min-width: 768px) {
    .image-box-quarter-height-container {
      background-image: url("./images/content/lug-nut-impact-driver-ipad.png"); } }
  @media screen and (min-width: 1024px) {
    .image-box-quarter-height-container {
      background-image: url("./images/content/long-bed-tow-truck-ipadpro.png"); } }
  @media screen and (min-width: 1869px) {
    .image-box-quarter-height-container {
      margin: 0;
      background-image: url("./images/content/long-bed-tow-truck-desktop.png"); } }

.three-panel-image-box {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 100%; }

.center-image {
  position: relative;
  margin: auto;
  border-radius: 10px;
  min-height: 54px;
  min-width: 54px; }

.center-img {
  position: relative;
  display: block;
  height: 100%;
  margin: auto; }

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media screen and (min-width: 1260px) {
    .flex-center {
      padding-bottom: 12.0em; } }

.flex-center-content-column {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  padding: .8em; }
  @media screen and (min-width: 768px) {
    .flex-center-content-column {
      padding: 1.8em; } }
  @media screen and (min-width: 1024px) {
    .flex-center-content-column {
      padding: 2.2em; } }
  @media screen and (min-width: 1260px) {
    .flex-center-content-column {
      padding: 3.6em; } }

.bk-img-home-header {
  background-image: url(./images/banners/elite-towing-and-transporting-home-header-image-mobile.jpg);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-home-header {
      background-image: url(./images/banners/elite-towing-and-transporting-home-header-image-tablet.jpg); } }
  @media screen and (min-width: 1100px) {
    .bk-img-home-header {
      background-image: url(./images/banners/elite-towing-and-transporting-home-header-image-desktop.jpg); } }

.bk-img-review {
  background-image: url(./images/content/ear-listen-customer-reviews-mobile.png);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-review {
      background-image: url(./images/content/ear-listen-customer-reviews-mobile.png); } }

.bk-img-customer-quotes {
  background-image: url(./images/content/customer-review-quotation-marks-mobile.png);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-customer-quotes {
      background-image: url(./images/content/customer-review-quotation-marks.png); } }

.bk-img-home-icon {
  background-image: url(./images/logos-icons/home-page-house-icon-mobile.png);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-home-icon {
      background-image: url(./images/logos-icons/home-page-house-icon-mobile.png); } }

.bk-img-contact-icon {
  background-image: url(./images/logos-icons/contact-phone-envelope-icons.png);
  background-repeat: no-repeat;
  background-position: center; }

.bk-img-towing-transporting {
  background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car.jpg);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-towing-transporting {
      background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car-tablet.jpg); } }
  @media screen and (min-width: 1024px) {
    .bk-img-towing-transporting {
      background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car-ipadpro.jpg); } }
  @media screen and (min-width: 1100px) {
    .bk-img-towing-transporting {
      background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car-desktop.jpg); } }

.bk-img-towing-repair-work {
  background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed.jpg);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-towing-repair-work {
      background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed-tablet.jpg); } }
  @media screen and (min-width: 1024px) {
    .bk-img-towing-repair-work {
      background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed-ipadpro.jpg); } }
  @media screen and (min-width: 1100px) {
    .bk-img-towing-repair-work {
      background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed-desktop.jpg); } }

.bk-img-loading-tow-truck {
  background-image: url(./images/content/car-being-loaded-onto-tow-truck.jpg);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-loading-tow-truck {
      background-image: url(./images/content/car-being-loaded-onto-tow-truck-tablet.jpg); } }
  @media screen and (min-width: 1024px) {
    .bk-img-loading-tow-truck {
      background-image: url(./images/content/car-being-loaded-onto-tow-truck-ipadpro.jpg); } }
  @media screen and (min-width: 1100px) {
    .bk-img-loading-tow-truck {
      background-image: url(./images/content/car-being-loaded-onto-tow-truck-desktop.jpg); } }

.bk-img-dead-battery-charge {
  background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery.jpg);
  background-repeat: no-repeat;
  background-position: center; }
  @media screen and (min-width: 768px) {
    .bk-img-dead-battery-charge {
      background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery-tablet.jpg); } }
  @media screen and (min-width: 1024px) {
    .bk-img-dead-battery-charge {
      background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery-ipadpro.jpg); } }
  @media screen and (min-width: 1100px) {
    .bk-img-dead-battery-charge {
      background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery-desktop.jpg); } }

/* ------------------------------------------------ CLIP-PATHS ---------------------------------------------------------------- */
.arrow-down {
  clip-path: polygon(0% 60%, 20% 60%, 20% 0%, 80% 0%, 80% 60%, 100% 60%, 50% 100%); }

.arrow-up {
  clip-path: polygon(20% 100%, 80% 100%, 80% 40%, 100% 40%, 50% 0%, 0% 40%, 20% 40%); }

.arrow-down-around {
  clip-path: polygon(50% 100%, 0% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 70% 60%, 70% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%);
  box-shadow: 0px -1px 4px rgba(103, 103, 104, 0.5); }
  @media screen and (min-width: 414px) {
    .arrow-down-around {
      clip-path: polygon(50% 100%, 0% 60%, 20% 60%, 20% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 80% 60%, 80% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%); } }

.arrow-down-enter {
  animation: 8s infinite down-enter; }

@keyframes down-enter {
  0% {
    clip-path: polygon(50% 0%, 0% 0%, 30% 0%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 0%, 100% 0%, 70% 0%, 70% 0%, 100% 0%, 100% 0%, 100% 100%, 50% 0%);
    box-shadow: 0px -1px 4px rgba(103, 103, 104, 0.5);
    opacity: 0; }
  60% {
    clip-path: polygon(50% 100%, 0% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 70% 60%, 70% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%);
    box-shadow: 0px -1px 4px rgba(103, 103, 104, 0.5);
    opacity: 100; }
  100% {
    clip-path: polygon(50% 100%, 0% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 70% 60%, 70% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%);
    box-shadow: 0px -1px 4px rgba(103, 103, 104, 0.5);
    opacity: 0; } }

.quarter-height-bottom-banner-overlay {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  min-height: 50%;
  margin-top: 0vh;
  background: #129fca;
  z-index: 0; }

.top-section-banner {
  position: relative;
  width: 100%;
  height: 100%;
  min-height: calc(25vh - 70px);
  z-index: 2;
  box-shadow: 0px 0px 4px rgba(103, 103, 104, 0.5); }

.full-box-overlay {
  position: absolute;
  height: 100%;
  width: 100%; }

.appear {
  opacity: 0;
  transition: .8s ease; }

.fixed-dark-overlay {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  background-repeat: no-repeat;
  background-position: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2; }

.greylight-rad-med {
  background: radial-gradient(#dcddde, #cbccce); }

.clear-rad-eliteblue {
  background: radial-gradient(rgba(18, 159, 202, 0), rgba(18, 159, 202, 0.25), #129fca); }

.coevo-charcoal {
  background-color: #696969; }

.light-white-o {
  background: linear-gradient(to bottom, #dfe0e0, #fffaf5); }

.greydar-rad-char-black {
  background: radial-gradient(#b8b9bb, #696969, #0e0e0e); }

.eliteb-rad-lightg-char {
  background: radial-gradient(#0d6f8d, #129fca, white); }

.rad-w-w-mdy {
  background: radial-gradient(white, white, #129fca); }

.rad-white-clear {
  background: radial-gradient(#fffbf7, rgba(255, 251, 247, 0), rgba(255, 251, 247, 0)); }

.vert-coevw-w-coevw {
  background: linear-gradient(to right, #fffbf7, white, #fffbf7); }

.nine-sixty-full-height {
  position: absolute;
  width: 100%;
  max-height: calc(100% - $smallMenuNavBar);
  padding: 19%; }

.center-text-box {
  margin: 0;
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%; }
  @media screen and (min-width: 768px) {
    .center-text-box {
      width: 55%;
      padding: 0px; } }
  @media screen and (min-width: 1024px) {
    .center-text-box {
      width: 45%; } }
  @media screen and (min-width: 1100px) {
    .center-text-box {
      width: 35%; } }

.grid-title-box {
  border-radius: 5px;
  margin-bottom: 0px; }
  @media screen and (min-width: 768px) {
    .grid-title-box {
      min-height: 96px; } }

#overlay-centered-text-box {
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  margin: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  padding: 15px 10px; }
  @media screen and (min-width: 768px) {
    #overlay-centered-text-box {
      width: 90%;
      margin: 0% 5%; } }
  @media screen and (min-width: 1024px) {
    #overlay-centered-text-box {
      width: 80%;
      margin: 0% 10%; } }
  @media screen and (min-width: 1100px) {
    #overlay-centered-text-box {
      width: 80%;
      margin: 0% 10%; } }

.overlay-appear-text {
  color: white;
  font-size: 14px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center; }
  @media screen and (min-width: 768px) {
    .overlay-appear-text {
      font-size: 20px; } }

@media screen and (max-width: 768px) {
  .no-show-mobile-text {
    display: none; } }

.one-third-text-box {
  display: inline-block;
  position: relative;
  height: auto;
  max-width: 33%;
  padding: 1.0em;
  text-align: center;
  vertical-align: middle;
  bottom: 75px;
  border-radius: 30px;
  border: 6px solid rgba(34, 34, 34, 0.8); }

#top-nav {
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 70px;
  z-index: 100; }

.top-nav-icon-box {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  height: 100%; }

.flex-icon-row-box {
  box-sizing: border-box;
  display: flex;
  width: 50%;
  background: linear-gradient(to top, #fffbf7, #D0D0D0);
  border: 5px solid #dcddde; }

.dropdown {
  position: relative;
  display: inline-block; }

.tow-contact {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: linear-gradient(to bottom, #64dbe6, #129fca, Black);
  color: white;
  font-size: 25px;
  padding: .40em 1.20em;
  border-radius: 40px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  text-align: center;
  text-decoration: none; }
  @media screen and (max-width: 320px) {
    .tow-contact {
      font-size: 22px;
      padding: .30em 1.20em; } }
  @media screen and (min-width: 1024px) {
    .tow-contact {
      font-size: 40px;
      padding: .75em 2em; } }
  @media screen and (min-width: 1600px) {
    .tow-contact {
      font-size: 40px;
      padding: .75em 3em; } }

.sticky-full-screen {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 100%;
  min-height: 100%; }

.z-layer-1 {
  z-index: 1; }

.z-layer-2 {
  z-index: 2; }

.z-layer-3 {
  z-index: 3; }

#contact-banner {
  position: fixed;
  top: 0px;
  height: 100vh;
  z-index: -1; }

#contact-content-wrapper {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 20px;
  width: 100%; }
  #contact-content-wrapper h1 {
    margin: 20px 0px;
    color: white;
    text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 48px; }
    @media screen and (max-width: 768px), screen and (max-height: 480px) {
      #contact-content-wrapper h1 {
        margin: 10px 0px 30px;
        padding-top: 30px;
        font-size: 32px; } }
  #contact-content-wrapper h2, #contact-content-wrapper h3 {
    color: white;
    text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3); }
  #contact-content-wrapper h2 {
    font-size: 1.75em; }
  #contact-content-wrapper h3 {
    font-size: 1.5em;
    margin: 10px 0px 30px; }
    @media screen and (min-width: 768px) {
      #contact-content-wrapper h3 {
        font-size: 1.6em; } }
  #contact-content-wrapper p {
    margin: 10px 0px;
    color: white;
    text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
    font-size: 20px;
    font-weight: bold;
    max-width: 960px; }
    @media screen and (max-width: 768px), screen and (max-height: 480px) {
      #contact-content-wrapper p {
        font-size: 20px; } }
  #contact-content-wrapper #contact-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-flex: 50%;
    -ms-flex: 50%;
    flex: 50%;
    margin-top: 75px; }
    #contact-content-wrapper #contact-area #options-container {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
      flex-flow: row wrap;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      padding: 0px 10px;
      width: 50%; }
      #contact-content-wrapper #contact-area #options-container .option {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 80px;
        width: 100%; }
        #contact-content-wrapper #contact-area #options-container .option a {
          text-decoration: none;
          cursor: pointer; }
          #contact-content-wrapper #contact-area #options-container .option a img {
            display: block;
            margin-right: 20px;
            width: 100%;
            height: 100%;
            max-width: 60px;
            max-height: 60px; }
        #contact-content-wrapper #contact-area #options-container .option p {
          margin: 20px 0px;
          width: 300px;
          font-size: 24px;
          font-weight: bold;
          text-shadow: -1px 2px 4px rgba(0, 0, 0, 0.3), 0px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 4px rgba(0, 0, 0, 0.3);
          font-weight: bold;
          color: white; }
          @media screen and (max-width: 240px) {
            #contact-content-wrapper #contact-area #options-container .option p {
              width: 100%; } }
        @media screen and (max-width: 768px), screen and (max-height: 480px) {
          #contact-content-wrapper #contact-area #options-container .option {
            margin-bottom: 50px; }
            #contact-content-wrapper #contact-area #options-container .option a img {
              max-width: 40px;
              max-height: 40px; }
            #contact-content-wrapper #contact-area #options-container .option a p {
              margin: 20px 0px;
              font-size: 20px;
              width: 250px; } }
        @media screen and (max-width: 699px) {
          #contact-content-wrapper #contact-area #options-container .option a img {
            margin: 0px 20px; } }
        @media screen and (max-width: 580px) {
          #contact-content-wrapper #contact-area #options-container .option a {
            width: 100%; }
            #contact-content-wrapper #contact-area #options-container .option a img {
              margin: 0px auto; }
            #contact-content-wrapper #contact-area #options-container .option a p {
              width: 100%;
              text-align: center; }
            #contact-content-wrapper #contact-area #options-container .option a #email-prompt {
              margin-bottom: 0px; } }
    #contact-content-wrapper #contact-area form {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0px 20px;
      width: 50%; }
      #contact-content-wrapper #contact-area form .inputData {
        margin: 10px 0px;
        padding: 20px;
        width: 100%;
        max-width: 480px;
        height: 40px;
        border: solid 2px white;
        border-radius: 30px;
        background-color: white;
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: #696969;
        font-weight: bold; }
        #contact-content-wrapper #contact-area form .inputData:focus:valid, #contact-content-wrapper #contact-area form .inputData.filled:valid {
          border: solid 2px #00DC00; }
        #contact-content-wrapper #contact-area form .inputData:focus:invalid, #contact-content-wrapper #contact-area form .inputData.filled:invalid {
          border: solid 2px red; }
      #contact-content-wrapper #contact-area form textarea.inputData {
        height: 200px;
        resize: none; }
      #contact-content-wrapper #contact-area form #submit-button {
        margin: 10px 0px;
        padding: 0px 20px;
        height: 30px;
        line-height: 29px;
        border: unset;
        border-radius: 30px;
        background: linear-gradient(to bottom, #fbfbf8, rgba(46, 37, 37, 0.05));
        -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
        color: #696969;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer; }
        #contact-content-wrapper #contact-area form #submit-button:active {
          background: linear-gradient(to top, #fffbf7, #D0D0D0); }
        #contact-content-wrapper #contact-area form #submit-button:disabled {
          color: #BBB; }
    @media screen and (max-width: 500px) {
      #contact-content-wrapper #contact-area #options-container,
      #contact-content-wrapper #contact-area form {
        width: 100%; }
      #contact-content-wrapper #contact-area #options-container {
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center; }
        #contact-content-wrapper #contact-area #options-container .option p {
          text-align: center; } }
  #contact-content-wrapper hr {
    margin: 20px 0px;
    width: 100%;
    color: white;
    -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }
  #contact-content-wrapper #address-area {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    margin-bottom: 20px;
    width: 100%; }
    #contact-content-wrapper #address-area p {
      -webkit-box-flex: 40%;
      -ms-flex: 40%;
      flex: 40%;
      margin: 0px 0px 10px 0px; }
    #contact-content-wrapper #address-area iframe {
      -webkit-box-flex: 60%;
      -ms-flex: 60%;
      flex: 60%;
      width: 100%;
      height: 480px;
      border: 4px solid #dcddde;
      -webkit-box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 1px 2px 4px 0px rgba(0, 0, 0, 0.2), 0px 2px 4px 0px rgba(0, 0, 0, 0.2), -1px 2px 4px 0px rgba(0, 0, 0, 0.2); }

.grecaptcha-badge {
  z-index: 100; }
