
.greylight-rad-med {
    background: $Greylig-rad-med;
}

.clear-rad-eliteblue {
    background: radial-gradient(rgba(18, 159, 202, 0), rgba(18, 159, 202, .25), $EliteBlue);
}

.coevo-charcoal {
    background-color: $CoEvoCharcoal;
}

.light-white-o {
    background: linear-gradient(to bottom, #dfe0e0, rgb(255, 250, 245));
}

.greydar-rad-char-black {
    background: $Greydar-rad-char-black;
}

.eliteb-rad-lightg-char {
    background: $EliteB-rad-lightg-char;
}

.rad-w-w-mdy { 
    background: $Rad-W-W-EliteBlue;
}

.rad-white-clear {
    background: radial-gradient($CoEvoWhite-o, rgba(255, 251, 247, 0), rgba(255, 251, 247, 0));
}

.vert-coevw-w-coevw {
    background: $vert-coevW-W-coevW;
}