#logo-wrapper {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    z-index: 110;
    padding-left: 5%;

    #logo {
        position: absolute;
        cursor: pointer;
        overflow: hidden; //Chop off the edges around the logo image so it becomes a circle

        img {
            display: block;
            height: 100%;
            width: 100%;
        }
    }

    .large {
        background-color: white;
        width: $logoSize;
        height: $logoSize;
        border: $borderStyle;
        border-radius: 30%;
        box-shadow: $circleShadow;
    }

    .small {
        width: 70px;
        height: 70px;
        border: $borderStyle;
        border-radius: 30%;
        box-shadow: $circleShadow;
    }

    // At or below 745px wide or 480px tall, 
    // logo size is reduced to half
    @media screen and (max-width: #{$iPad}),
    screen and (max-height: #{$landscapeBP}) {
        justify-content: flex-start;
        width: 30%;

        .large {
            width: $logoMobile;
            height: $logoMobile;
        }
    }

    @media screen and (min-width: $iPadPro) {
        justify-content: flex-start;
        width: 30%;

    }

    // Smallest width - the 'large' class is not used, everything is toggled
    @media screen and (max-width: #{$iPhone5}) {
        width: 75px;
    }
}