.quarter-height-bottom-banner-overlay {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    width: 100%;
    min-height: 50%; 
    margin-top: 0vh;
    background: $EliteBlue;
    z-index: 0;
}

.top-section-banner {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: calc(25vh - 70px);
  
    z-index: 2;
    box-shadow: $Surroundshadow;
}

.full-box-overlay {
    position: absolute; 
    height: 100%;
    width: 100%;
}

.appear {
    opacity: 0;
    transition: .8s ease;
}

.fixed-dark-overlay {
    position: absolute;
    background: rgba(0, 0, 0, 0.2);
    background-repeat: no-repeat;
    background-position: center;
    top:0;
    right:0;
    bottom:0;
    left:0;
    z-index: 2;
}