#contact-banner {
    // Contact page banner acts as a background
    position: fixed;
    top: 0px;
    height: 100vh;
    z-index: -1;
}

#contact-content-wrapper {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-flow: column nowrap;
            flex-flow: column nowrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    
    padding: 0px 20px;
    width: 100%;


    h1 {
        margin: 20px 0px;

        color: white;
        text-shadow: $textShadow;
        font-size: 48px;

        @media screen and (max-width: #{$iPad}),
        screen and (max-height: #{$MinDesktop}) {
            margin: 10px 0px 30px;
            padding-top: 30px;
            font-size: 32px;
        }
    }

    h2, h3 {
        color: white;
        text-shadow: $textShadow;
    }

    h2 {
        font-size: 1.75em;
    }

    h3 {
        font-size: 1.5em;
        margin: 10px 0px 30px;
        @media screen and (min-width: $iPad) {
            font-size: 1.6em;
        }
    }

    p {
        margin: 10px 0px;

        color: white;
        text-shadow: $textShadow;
        font-size: 20px;
        font-weight: bold;
        max-width: 960px;

        @media screen and (max-width: #{$iPad}),
        screen and (max-height: #{$MinDesktop}) {
            font-size: 20px;
        }
    }

    #contact-area {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;

        -webkit-box-flex: 50%;

            -ms-flex: 50%;

                flex: 50%;
        margin-top: 75px;

        #options-container {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-flow: row wrap;
                    flex-flow: row wrap;
            -webkit-box-pack: start;
                -ms-flex-pack: start;
                    justify-content: flex-start;

            padding: 0px 10px;
            width: 50%;

            .option {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-flow: row wrap;
                        flex-flow: row wrap;
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;
                -webkit-box-align: center;
                    -ms-flex-align: center;
                        align-items: center;

                margin-bottom: 80px;
                width: 100%;

                a {
                    text-decoration: none;
                    cursor: pointer;

                    img {
                        display: block;
                        margin-right: 20px;

                        width: 100%;
                        height: 100%;

                        max-width: 60px;
                        max-height: 60px;
                    }

                }

                p {
                    margin: 20px 0px;
                    width: 300px;
                    font-size: 24px;
                    font-weight: bold;
                    text-shadow: $textShadow;
                    font-weight: bold;
                    color: white;

                    @media screen and (max-width: 240px) {
                        width: 100%;
                    }
                }

                @media screen and (max-width: #{$iPad}),
                screen and (max-height: #{$MinDesktop}) {
                    margin-bottom: 50px;

                    a {
                        img {
                            max-width: 40px;
                            max-height: 40px;
                        }

                        p {
                            margin: 20px 0px;
                            font-size: 20px;
                            width: 250px;
                        }
                    }
                    
                }

                @media screen and (max-width: 699px) {
                    a {
                        img {
                            margin: 0px 20px;
                        }
                    }
                }

                @media screen and (max-width: 580px) {
                    a {
                        width: 100%;

                        img {
                            margin: 0px auto;
                        }

                        p {
                            width: 100%;
                            text-align: center;
                        }

                        #email-prompt {
                            margin-bottom: 0px;
                        }
                    }
                    
                }
            }
        }

        // End #options-container selector

        form {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-flow: column nowrap;
                    flex-flow: column nowrap;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            padding: 0px 20px;
            width: 50%;

            .inputData {
                margin: 10px 0px;
                padding: 20px;

                width: 100%;
                max-width: 480px;
                height: 40px;

                border: solid 2px white;
                border-radius: $borderRadius;
                background-color: white;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;

                color: $CoEvoCharcoal;
                font-weight: bold;

                &:focus:valid,
                &.filled:valid {
                    border: solid 2px #00DC00;
                }

                &:focus:invalid,
                &.filled:invalid {
                    border: solid 2px red;
                }

            }

            /// Size of message input box
            textarea.inputData {
                height: 200px;
                resize: none;
            }

            #submit-button {
                margin: 10px 0px;
                padding: 0px 20px;

                height: 30px;
                line-height: 29px;

                border: unset;
                border-radius: $borderRadius;
                background: $gradient;
                -webkit-box-shadow: $boxShadow;
                        box-shadow: $boxShadow;

                color: $CoEvoCharcoal;
                font-weight: bold;
                font-size: 16px;

                cursor: pointer;

                &:active {
                    background: $gradientReverse;
                }

                &:disabled {
                    color: #BBB;
                }
            }
        }

        // End form selector

        @media screen and (max-width: 500px) {

            #options-container,
            form {
                width: 100%;
            }

            #options-container {
                -webkit-box-pack: center;
                    -ms-flex-pack: center;
                        justify-content: center;

                .option {
                    p {
                        text-align: center;
                    }
                }
            }
        }

    }

    // End #contact-area

    hr {
        margin: 20px 0px;
        width: 100%;

        color: white;
        -webkit-box-shadow: $boxShadow;
                box-shadow: $boxShadow;

    }

    #address-area {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;

        margin-bottom: 20px;
        width: 100%;

        p {
            -webkit-box-flex: 40%;
                -ms-flex: 40%;
                    flex: 40%;

            margin: 0px 0px 10px 0px;
        }

        iframe {
            -webkit-box-flex: 60%;
                -ms-flex: 60%;
                    flex: 60%;

            width: 100%;
            height: 480px;

            border: $borderStyle;
            -webkit-box-shadow: $boxShadow;
                    box-shadow: $boxShadow;
        }
    }

}

.grecaptcha-badge {z-index: 100;}

// End #contact-content-wrapper selector