/* ------------------------------------------------ FULL SCREEN HEIGHT CONTAINERS -------------------------------------------------- */


// Full Screen Container Under Menu - Use this class when you want to fill the view height of the screen beneath the menu
.flex-100 {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100vw; 
    height: calc(100vh - 140px);

    @media screen and (min-width: $iPad) {
        height: calc(100vh - 157.5px);
    }

    @media screen and (min-width: $iPadPro) {
        height: calc(100vh - #{$largeDesktopMenuNavBar});
    }

}

.full-view-height-sub-menu-large {
    display: flex;
    flex-flow: row nowrap;
    align-items: stretch;
    width: 100vw; 
    min-height: calc(100vh - 140px);
   
    @media screen and (min-width: $iPad) {
        
        img {
            max-height: 417px;
            max-width: 417px;
        }
    }
    @media screen and (min-width: $iPadPro) {
        height: calc(100vh - #{$largeDesktopMenuNavBar});
    }
}

.full-view-height-sub-menu-small {
    position: relative;
    width: 100vw; 
    height: calc(100vh - 157.5px);

    @media screen and (min-width: $iPadPro) {
        height: calc(100vh - #{$largeDesktopMenuNavBar});
    }
}

.three-fourths-vh {
    position: relative;
    width: 100vw;
    height: 75vh;

    @media screen and (min-width: $iPad) {
        height: calc(75vh - 520px);
    }
    @media screen and (min-width: $iPadPro) {
        height: calc(75vh - 670px);
    }

    @media screen and (min-width: 1100px) {
        height: calc(75vh - 100px);
    }
}

.three-fourths-vh-sub-menu {

    width: 100%;
    min-height: 75%;

    @media screen and (min-width: $iPad) {
        min-height: calc(75vh - 100px);
    }

    @media screen and (min-width: 1100px) {
        height: 75%;
    }

}



/* ------------------------------------------------- 2/3 SCREEN HEIGHT CONTAINERS ---------------------------------------------- */

.two-thirds-vh-sub-menu {
    width: 100vw;
    height: calc(66.66vh - 105px);

    @media screen and (min-width: $iPadPro) {
        height: calc(66.66vh - 157.5px);
    }
}

.two-thirds-height-content-container {
    height: 66%;
    width: 100%;
}

.display-flex {
    display: flex;
}



/* -------------------------------------------------- HALF HEIGHT CONTAINERS ------------------------------------------- */

// Half Screen Height Container
.half-view-height-sub-menu-container {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100vw;
    height: 75vh;
    
    @media screen and (min-width: $iPad) {
        height: 50vh;
    }

}

// Use this container to take up the upper half of the .full-view-height-sub-menu
.half-height-content-container {
    position: relative;
    width: 100%; 
    height: 50%;
}

.half-height-flex-container {
    display:flex; 
    min-height: 50%;
    width: 100%;

}

.absolute-half-bottom {
    position: absolute;
    top: 50%;
}
.absolute-half-top {
    position: absolute;
    top: 0%;
}



/* ------------------------------------------------- 1/3 SCREEN HEIGHT CONTAINERS ---------------------------------------------- */

.flex-33 {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    width: 100vw; 
    min-height: calc(33.33vh - 35px);
}

.third-vh-sub-menu {
    width: 100vw;
    min-height: 33%;


}

.third-height-content-container {
    position: relative;
    min-height: 33.33%;
    width: 100%;
}




/* ------------------------------------------------- HALF SCREEN WIDTH CONTAINERS ---------------------------------------------- */

.mobile-full-desktop-half {
    position: static;
    width: 100%;
    height: 50%;
    display: inline-block;

    @media screen and (min-width:$iPad) {
        width: 50%;
        height: 100%;
    }
}

.half-width-relative-container {
    position: relative;
    height: 100%;
    width: 50%;
    display: inline-block;
}

.half-width-container {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: stretch;
    width: 50%;
}

.flex-order-1 {
    order: 1;
}
.flex-order-2 {
    order: 2;
}
.flex-order-3 {
    order: 3;
}

/* --------------------------------------------- GRID CONTAINERS --------------------------------------*/

.grid-container {
    display: grid;
    position: relative;
    background-color: $EliteYellow;
    padding: .6em;
    justify-content: center;
}

.auto-c-3 {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

    @media screen and (min-width:$iPad) {
        grid-template-columns: repeat(3, 1fr);
    }

}

.grid-item {
    background-color: $EliteYellow;

    font-size: 20px;
    text-align: center;
    overflow: hidden;

    @media screen and (min-width:$iPad) {
        background-color: white;
        
        text-align: center;
    }
    @media screen and (min-width:$iPadPro) {
        font-size: 30px;
    }
}

.grid-item img {
    align-self: center;
}


/* ----------------------------------------------- IMAGE POSITIONING CONTAINERS ----------------------------------------- */
// Places image inside if you want them off center to the right
.image-box-shift-right-quarter {
    
    width: 100%; 
    height: 100%; 
    padding-left: 50%;

    
}

.image-box-shift-right-half {
    position: relative;
    width: auto; 
    height: 100%; 
    max-height: 100%;
    padding-left: 50%;
    overflow: hidden;

    img {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50%;
    }
}


.image-box-quarter-height-container {

    background-image: url('./images/content/lug-nut-impact-driver.png');
    background-repeat: no-repeat;
    background-position: bottom right;
    height: 100%; 
    width: 100%;

    @media screen and (min-width: $iPad) {
        background-image: url('./images/content/lug-nut-impact-driver-ipad.png');
    }

    @media screen and (min-width: $iPadPro) {
        background-image: url('./images/content/long-bed-tow-truck-ipadpro.png');
    }

    @media screen and (min-width: 1869px) {
        margin: 0;
        background-image: url('./images/content/long-bed-tow-truck-desktop.png');
    }

}

.three-panel-image-box {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    height: 100%;
    width: 100%;
}

.center-image {
    position: relative;
    margin: auto;
    
    border-radius: 10px;
    min-height: 54px;
    min-width: 54px;

}

.center-img {
    position: relative;
    display: block;
    height: 100%;
    margin: auto;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (min-width: 1260px) {
        padding-bottom: 12.0em;
    }
}
.flex-center-content-column {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column nowrap;
    padding: .8em;

    @media screen and (min-width: $iPad) {
        padding: 1.8em;
    }
    @media screen and (min-width: $iPadPro) {
        padding: 2.2em;
    }
    @media screen and (min-width: 1260px) {
        padding: 3.6em;
    }
}




.bk-img-home-header {
    background-image: url(./images/banners/elite-towing-and-transporting-home-header-image-mobile.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/banners/elite-towing-and-transporting-home-header-image-tablet.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/banners/elite-towing-and-transporting-home-header-image-desktop.jpg);
    }
}


.bk-img-review {
    background-image: url(./images/content/ear-listen-customer-reviews-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/ear-listen-customer-reviews-mobile.png);
    }
}

.bk-img-customer-quotes {
    background-image: url(./images/content/customer-review-quotation-marks-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/customer-review-quotation-marks.png);
    }
}

.bk-img-home-icon {
    background-image: url(./images/logos-icons/home-page-house-icon-mobile.png);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/logos-icons/home-page-house-icon-mobile.png);
    }
}

.bk-img-contact-icon {
    background-image: url(./images/logos-icons/contact-phone-envelope-icons.png);
    background-repeat: no-repeat;
    background-position: center;
}


.bk-img-towing-transporting {
    background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car-tablet.jpg);
    }
    @media screen and (min-width: $iPadPro) {
        background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car-ipadpro.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/elite-towiing-and-transporting-truck-driving-towards-broken-down-car-desktop.jpg);
    }
}

.bk-img-towing-repair-work {
    background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed-tablet.jpg);
    }
    @media screen and (min-width: $iPadPro) {
        background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed-ipadpro.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/tow-truck-driver-using-levers-to-lower-and-lift-truck-bed-desktop.jpg);
    }
}

.bk-img-loading-tow-truck {
    background-image: url(./images/content/car-being-loaded-onto-tow-truck.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/car-being-loaded-onto-tow-truck-tablet.jpg);
    }
    @media screen and (min-width: $iPadPro) {
        background-image: url(./images/content/car-being-loaded-onto-tow-truck-ipadpro.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/car-being-loaded-onto-tow-truck-desktop.jpg);
    }
}

.bk-img-dead-battery-charge {
    background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery.jpg);
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (min-width: $iPad) {
        background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery-tablet.jpg);
    }
    @media screen and (min-width: $iPadPro) {
        background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery-ipadpro.jpg);
    }
    @media screen and (min-width: 1100px) {
        background-image: url(./images/content/tow-truck-driver-jumping-dead-truck-battery-desktop.jpg);
    }
}





/* ------------------------------------------------ CLIP-PATHS ---------------------------------------------------------------- */

.arrow-down {
    clip-path: polygon(0% 60%, 20% 60%, 20% 0%, 80% 0%, 80% 60%, 100% 60%, 50% 100%)
}

.arrow-up {
    clip-path: polygon(20% 100%, 80% 100%, 80% 40%, 100% 40%, 50% 0%, 0% 40%, 20% 40%);
}

.arrow-down-around {
    clip-path: polygon(50% 100%, 0% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 70% 60%, 70% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%); 
    box-shadow: $Topshadow;

    @media screen and (min-width: $iPhone6-7-8) {
        clip-path: polygon(50% 100%, 0% 60%, 20% 60%, 20% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 80% 60%, 80% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%);
    }
}

.arrow-down-enter {animation: 8s infinite down-enter;}

@keyframes down-enter {
    0% {clip-path: polygon(50% 0%, 0% 0%, 30% 0%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 0%, 100% 0%, 70% 0%, 70% 0%, 100% 0%, 100% 0%, 100% 100%, 50% 0%); 
        box-shadow: $Topshadow;
        opacity: 0;
    }
    60% {clip-path: polygon(50% 100%, 0% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 70% 60%, 70% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%); 
        box-shadow: $Topshadow;
        opacity: 100;
    }
    100% {clip-path: polygon(50% 100%, 0% 60%, 30% 60%, 30% 0%, 0% 0%, 0% 60%, 0% 100%, 50% 100%, 100% 60%, 70% 60%, 70% 0%, 100% 0%, 100% 60%, 100% 100%, 50% 100%); 
        box-shadow: $Topshadow;
        opacity: 0;
    }
}




