#nav-menu {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    width: 50%;
    height: 100%;

    @import 'menu_icon';

    #menu-icon {
        display: none;
    }

    #site-links {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 130;
        width: 100%;

        .siteLink {
            color: black;
            padding: .5em 1.5em;
            text-align: center;
            font-weight: bold;
            text-decoration: none;
            white-space: nowrap;
            border-radius: 20px;

            @media screen and (min-width: 1200px) {
                padding: .5em 3em;
            }

            cursor: pointer;

            &:hover {
                color: #0d6f8d;
                background: $EliteYellow;
                border: 5px solid $EliteBlue;
            }
        }

        .big-menu-btn {
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            font-size: 20px;
            background: $lightb-eliteb-black;
            color: black;
            padding: .75em 3em;
            border-radius: 10px;
            @media screen and (min-width: $iPad) {
                color: white;
                padding: .8em 2.25em; 
            }
            @media screen and (min-width: $iPadPro) {
                border-radius: 40px;
                color: white;
                padding: 1em 1.75em;            }
            @media screen and (min-width: 1200px) {
                font-size: 40px;
                color: white;
                padding: 1.25em 2.75em;
            }
        }
    }

    // At or below 768px wide or 480px tall, 
    // logo decreases so nav menu width needs to be adjusted
    @media screen and(max-width: 767px),
    screen and (max-height: #{$landscapeBP}) {
        width: 50%;

        #site-links {
            .siteLink {
                font-size: 14px;
                margin: 10px;
            }
        }
    }

    @media screen and(min-width: $iPad) {
        width: 70%;
        #site-links {
            .siteLink {
                font-size: 22px;
                margin: 10px;
            }
        }
    }

    @media screen and (min-width: $iPadPro) {
        width: 70%;
        #site-links {
            .siteLink {
                margin: 10px;
                font-size: 30px;
            }
        }
    }
    

    // At or below 570px wide, hide the site links and reveal the menu icon button.
    // Site links are revealed via the menu icon button
    @media screen and (max-width: #{$mobileMenuIcon}) {
        position: relative;
        flex-flow: column nowrap;
        
        // justify-content: space-between;

        // overflow: visible;

        #menu-icon {
            display: flex;
        }

        #site-links {
            position: absolute;
            top: $MenuHeightDesktop / 2;

            flex-flow: column nowrap;

            height: 0px;

            overflow: hidden;

            background: rgba(5, 42, 54, .7);
            border-bottom-left-radius: $borderRadius;
            border-bottom-right-radius: $borderRadius;

            .siteLink {
                margin: 16px 10px;
                padding: 15px;
                width: 85%;
                border-radius: 30px;
                background: linear-gradient(to bottom, rgba(251, 251, 248, 1.0),rgba(206, 206, 206, 0.8), rgba(251, 251, 248, 1.0));
                box-shadow: $boxShadow;

                &.active {
                    background: $gradientReverse;
                }
            }
        }
    }

    // Smallest width - the 'large' class is not used, everything is toggled
    @media screen and (max-width: $iPhone5) {
        width: calc(100% - 75px);

        #menu-icon {
            transform: translateX(calc((100vw - 120px - 20px) / 2 - 60px));
        }

        #site-links {
            top: 70px;
            transform: translateX(calc((100vw - 140px - 20px) / 2 - 50px));
        }
    }
}